import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LanguageTranslationModule } from './shared/language-translation/language-translation.module';
import { HeaderComponent } from './shared/header/header.component';
import { IdentityService } from './services/identity.service';
import { LoaderService } from './services/loader.service';
import { RequestInterceptor } from './interceptors/request.interceptor';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BildverifizierungService } from './services/bildverifizierung.service';
import { BildverifizierungComponent } from './bildverifizierung/bildverifizierung.component';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { MatBadgeModule } from '@angular/material/badge';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { TableModule } from 'primeng/table';
import { MatTableModule } from '@angular/material/table';

@NgModule({
  declarations: [
    AppComponent,
    BildverifizierungComponent,
    HeaderComponent,
    SpinnerComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LanguageTranslationModule,
    MatToolbarModule,
    MatButtonModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    MatSelectModule,
    MatFormFieldModule,
    MatGridListModule,
    MatTooltipModule,
    MatCardModule,
    MatBadgeModule,
    TableModule,
    MatTableModule
  ],
  providers: [BildverifizierungService, IdentityService, {
    provide: HTTP_INTERCEPTORS,
    useClass: RequestInterceptor,
    deps: [LoaderService],
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
