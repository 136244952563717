import { AfterViewInit, Component } from '@angular/core';
import { User } from 'src/app/models/user';
import { IdentityService } from 'src/app/services/identity.service';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements AfterViewInit {
  protected user: User = new User();

  constructor(
    private identityService: IdentityService) {
  }
  
  ngAfterViewInit(): void {
    this.identityService.getBenutzer().subscribe((user) => {
      this.user = user;
    });
  }

  public logout(): void {

  }
}
