import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { Zutrittspunkt } from '../models/zutrittspunkt';
import * as config from "../../environments/environment";
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BildverifizierungService {
  private eventSourceZutrittspunkt1!: EventSource;
  private eventSourceZutrittspunkt2!: EventSource;

  constructor(private zone: NgZone, private http: HttpClient) { }

  getServerSentEventZutrittspunkt1(projektId: string, leseeinheitId: string): Observable<Object> {
    if(this.eventSourceZutrittspunkt1){
      this.closeZutrittspunkt1();
    }
    this.eventSourceZutrittspunkt1 = new EventSource(config.environment.sse_environment + `/api/zutrittskontrolle/sse/verbinden/?ProjektId=${projektId}&LeseeinheitId=${leseeinheitId}`, {
      withCredentials: true
    });
    return new Observable<MessageEvent>(observer => {
      this.eventSourceZutrittspunkt1.onmessage = event => {
        this.zone.run(() => observer.next(event.data ? JSON.parse(event.data) : "error"));
      };
      this.eventSourceZutrittspunkt1.onerror = error => {
        this.zone.run(() => {
          console.log("error", error);
        });
      };
    });
  }

  getServerSentEventZutrittspunkt2(projektId: string, leseeinheitId: string): Observable<Object> {
    if(this.eventSourceZutrittspunkt2){
      this.closeZutrittspunkt2();
    }
    this.eventSourceZutrittspunkt2 = new EventSource(config.environment.sse_environment + `/api/zutrittskontrolle/sse/verbinden/?ProjektId=${projektId}&LeseeinheitId=${leseeinheitId}`, {
      withCredentials: true
    });
    return new Observable<MessageEvent>(observer => {
      this.eventSourceZutrittspunkt2.onmessage = event => {
        this.zone.run(() => observer.next(event.data ? JSON.parse(event.data) : "error"));
      };
      this.eventSourceZutrittspunkt2.onerror = error => {
        this.zone.run(() => {
          console.log("error", error);
        });
      };
    });
  }

  getZutrittspunkte(projektId: string): Observable<Array<Zutrittspunkt>> {
    return this.http.get<Array<Zutrittspunkt>>(config.environment.baseUri + `/api/zutrittskontrolle/bildverifizierung/de-DE/${projektId}/leseeinheiten`);
  }

  public closeZutrittspunkt1(): void {
    if (this.eventSourceZutrittspunkt1)
      this.eventSourceZutrittspunkt1.close();
  }

  public closeZutrittspunkt2(): void {
    console.log(this.eventSourceZutrittspunkt2);
    if (this.eventSourceZutrittspunkt2)
      this.eventSourceZutrittspunkt2.close();
  }
}
