<mat-toolbar class="background-secondary">
  <a class="logo" routerLink="">
    <img src="../../../assets/images/icon-algo-logo-ohne-name.svg" />
  </a>
  <span class="example-spacer"></span>
  <button mat-button class="profilmenu color-secondary" [matMenuTriggerFor]="menu" title="{{'Profiloptionen' | translate}}">
    <i class="fa-duotone fa-circle-user icon-fix-width"></i>
    <span matTooltip="{{'Usermenu' | translate}}">{{user.vorname}} {{user.nachname}}</span>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="logout()">
      <i class="fa-solid fa-right-from-bracket icon-fix-width"></i>
      <span  matTooltip="{{'Ausloggen' | translate}}">{{'Ausloggen' | translate}}</span>
    </button>
  </mat-menu>
</mat-toolbar>