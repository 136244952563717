import { Component, OnInit } from '@angular/core';
import { IdentityService } from './services/identity.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  
  constructor(
    private identityService: IdentityService) {
   
   }

  ngOnInit(): void {
    this.identityService.loadBenutzerprofil();
  }

  
}
