export class User {
    id: string = "";
    vorname: string = "";
    nachname: string = "";
    unternehmen: string = "";
    isAuthenticated: boolean = false;
    roles: Array<string> = new Array<string>();
    jwtToken: string = "";

    constructor(id?: string, vorname?: string, nachname?: string, unternehmen?: string, istAuthentifiziert?: boolean, rollen?: Array<string>, token?: string) {
        this.id = id || "";
        this.vorname = vorname || "";
        this.nachname = nachname || "";
        this.unternehmen = unternehmen || "";
        this.isAuthenticated = istAuthentifiziert || false;
        this.roles = rollen || new Array<string>();
        this.jwtToken = token || "";
    }
}
