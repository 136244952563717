<div class="m-20" style="text-align: center;">
    <mat-grid-list rows="2" cols="{{isPhonePortrait ? 1: 2}}" colWidth="50%"
        rowHeight="{{personal1 || personal2 ?'900px': '600px'}}">
        <mat-grid-tile colspan="1" [ngStyle]="{'height':personal1 || personal2 ?'900px': '600px'}">
            <mat-card
                [ngClass]="{'background-success': personal1 && personal1.Success == true, 'background-accent':  personal1 && personal1.Success !== true}">
                <mat-card-header>
                    <mat-card-title>
                        <mat-label>{{'LESEEINHEIT' | translate}}</mat-label>
                        <button *ngIf="istVerbundenZutrittspunkt1 === true" mat-icon-button
                            title="{{'TRENNE_VERBINDUNG_LESEINHEIT' | translate}}"
                            (click)="disconnectZutrittspunkt1()"><i class="fa-duotone fa-users-slash"></i></button>
                        <mat-select panelWidth="auto" matTooltip="{{'LESEEINHEIT_AUSWAEHLEN' | translate}}"
                            [(value)]="selectedLeseinheitId1"
                            (selectionChange)="connectServerSentEventZutrittspunkt1($event)">
                            @for (zutrittspunkt of zutrittspunkte; track zutrittspunkt) {
                            <mat-option [value]="zutrittspunkt.leseeinheitId">{{zutrittspunkt.zutrittspunktName}} -
                                {{'RICHTUNG' + zutrittspunkt.richtung | translate }}</mat-option>
                            }
                        </mat-select>
                    </mat-card-title>
                </mat-card-header>
                <img mat-card-image src="{{imgSrc1}}" class="profilbild" />
                @if(personal1 && (personal1.PersonalId !== '' || !personal1.PersonalId)){
                <mat-card-content>
                    <p>{{personal1.Nachname}} {{personal1.Vorname}}</p>
                    <p>{{personal1.Firmenname}}</p>
                    <p>{{personal1.Kartennummer}}</p>
                    <p>{{personal1.Timestamp | date: 'dd.MM.YYYY HH:mm'}}</p>
                </mat-card-content>
                }@else if(istVerbundenZutrittspunkt1 == false){
                <mat-card-content>
                    <p>{{'GETRENNT' | translate}}</p>
                </mat-card-content>
                }@else {
                <mat-card-content>
                    <p>{{'VERBUNDEN' | translate}}</p>
                    <p>{{'KEIN_ZUTRITTSEREIGNIS' | translate}}</p>
                </mat-card-content>
                }
            </mat-card>
        </mat-grid-tile>
        <mat-grid-tile colspan="1" [ngStyle]="{'height':personal1 || personal2 ?'900px': '600px'}">
            <mat-card
                [ngClass]="{'background-success': personal2 && personal2.Success == true, 'background-accent':  personal2 && personal2.Success !== true}">
                <mat-card-header>
                    <mat-card-title>
                        <mat-label>{{'LESEEINHEIT' | translate}}</mat-label>
                        <button *ngIf="istVerbundenZutrittspunkt2 === true" mat-icon-button
                            (click)="disconnectZutrittspunkt2()"><i class="fa-duotone fa-users-slash"></i></button>
                        <mat-select panelWidth="auto" matTooltip="{{'LESEEINHEIT_AUSWAEHLEN' | translate}}"
                            [(value)]="selectedLeseinheitId2"
                            (selectionChange)="connectServerSentEventZutrittspunkt2($event)">
                            @for (zutrittspunkt of zutrittspunkte; track zutrittspunkt) {
                            <mat-option [value]="zutrittspunkt.leseeinheitId">{{zutrittspunkt.zutrittspunktName}} -
                                {{'RICHTUNG' + zutrittspunkt.richtung | translate }}</mat-option>
                            }
                        </mat-select>
                    </mat-card-title>
                </mat-card-header>
                <img mat-card-image src="{{imgSrc2}}" class="profilbild" />
                @if(personal2 && (personal2.PersonalId !== '' || !personal2.PersonalId)){ <mat-card-content>
                    <p>{{personal2.Nachname}} {{personal2.Vorname}}</p>
                    <p>{{personal2.Firmenname}}</p>
                    <p>{{personal2.Kartennummer}}</p>
                    <p>{{personal2.Timestamp | date: 'dd.MM.YYYY HH:mm'}}</p>
                </mat-card-content>
                }@else if(istVerbundenZutrittspunkt2 == false){
                <mat-card-content>
                    <p>{{'GETRENNT' | translate}}</p>
                </mat-card-content>
                }@else {
                <mat-card-content>
                    <p>{{'VERBUNDEN' | translate}}</p>
                    <p>{{'KEIN_ZUTRITTSEREIGNIS' | translate}}</p>
                </mat-card-content>
                }
            </mat-card>
        </mat-grid-tile>
    </mat-grid-list>
</div>