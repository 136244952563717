import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Zutrittspunkt } from '../models/zutrittspunkt';
import { BildverifizierungService } from '../services/bildverifizierung.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { SubscriptionLike } from 'rxjs';
import { Personal } from '../models/personal';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-bildverifizierung',
  templateUrl: './bildverifizierung.component.html',
  styleUrl: './bildverifizierung.component.scss'
})
export class BildverifizierungComponent implements OnInit, OnDestroy {
  protected projektId: string = "";
  protected imgSrc1: string =
    "../../assets/images/placeholder-mitarbeiter-square.png";
  protected imgSrc2: string =
    "../../assets/images/placeholder-mitarbeiter-square.png";
  protected istVerbundenZutrittspunkt1: boolean = false;
  protected istVerbundenZutrittspunkt2: boolean = false;
  protected isPhonePortrait: boolean = false;
  protected selectedLeseinheitId1!: string;
  protected personal1!: Personal;
  protected selectedLeseinheitId2!: string;
  protected personal2!: Personal;
  protected zutrittspunkte: Array<Zutrittspunkt> = new Array<Zutrittspunkt>();
  private eventSourceSubscription1!: SubscriptionLike;
  private eventSourceSubscription2!: SubscriptionLike;

  constructor(private route: ActivatedRoute, private bildverfizierugService: BildverifizierungService, private responsive: BreakpointObserver) { }

  public ngOnInit(): void {
    this.projektId = this.route.snapshot.paramMap.get('projektId') || "";
    this.responsive.observe([Breakpoints.Small, Breakpoints.HandsetPortrait])
      .subscribe(result => {
        this.isPhonePortrait = result?.matches;
      });
    this.bildverfizierugService.getZutrittspunkte(this.projektId).subscribe((data) => {
      this.zutrittspunkte = data;
    });
  }

  public ngOnDestroy(): void {
    this.bildverfizierugService.closeZutrittspunkt1();
    this.bildverfizierugService.closeZutrittspunkt2();    
    this.eventSourceSubscription1?.unsubscribe();
    this.eventSourceSubscription2?.unsubscribe();
  }

  protected connectServerSentEventZutrittspunkt1(event: MatSelectChange): void {
    let projektId = this.projektId;
    if (this.istVerbundenZutrittspunkt1) {
      this.disconnectZutrittspunkt1(this.selectedLeseinheitId1);
    }
    this.eventSourceSubscription1 = this.bildverfizierugService.getServerSentEventZutrittspunkt1(projektId, this.selectedLeseinheitId1)
      .subscribe(event => {
        if (event?.toString().includes("Verbunden") && this.istVerbundenZutrittspunkt1 === false) {
          this.istVerbundenZutrittspunkt1 = true;
        } else if (JSON.stringify(event).includes("PersonalId")) {
          this.personal1 = event as Personal;
          this.imgSrc1 = this.personal1?.Profilbild ? "data:image/*;base64," + this.personal1.Profilbild : "../../assets/images/placeholder-mitarbeiter-square.png";

        }

      });
  }

  protected disconnectZutrittspunkt1(leseeinheitId?: string): void {
    this.selectedLeseinheitId1 = leseeinheitId ? leseeinheitId : "";
    this.istVerbundenZutrittspunkt1 = false;
    this.personal1 = {} as Personal;
    this.imgSrc1 =
    "../../assets/images/placeholder-mitarbeiter-square.png";
    this.bildverfizierugService.closeZutrittspunkt1();
  }

  protected connectServerSentEventZutrittspunkt2(event: MatSelectChange): void {
    let projektId = this.projektId;
    if (this.istVerbundenZutrittspunkt2) {
      this.disconnectZutrittspunkt2(this.selectedLeseinheitId2);
    }
    this.eventSourceSubscription2 = this.bildverfizierugService.getServerSentEventZutrittspunkt2(projektId, this.selectedLeseinheitId2)
      .subscribe(event => {
        if (event?.toString().includes("Verbunden") && !this.istVerbundenZutrittspunkt2) {
          this.istVerbundenZutrittspunkt2 = true;
        } else if (JSON.stringify(event).includes("PersonalId")) {
          this.personal2 = event as Personal;
          this.imgSrc2 = this.personal2?.Profilbild ? "data:image/*;base64," + this.personal2.Profilbild : "../../assets/images/placeholder-mitarbeiter-square.png";
        }
      });
  }

  protected disconnectZutrittspunkt2(leseeinheitId?: string): void {
    this.selectedLeseinheitId2 = leseeinheitId ? leseeinheitId : "";
    this.istVerbundenZutrittspunkt2 = false;
    this.personal2 = {} as Personal;
    this.imgSrc2 =
    "../../assets/images/placeholder-mitarbeiter-square.png";
    this.bildverfizierugService.closeZutrittspunkt2();
  }
}
