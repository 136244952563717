import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models/user';
import * as config from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class IdentityService {

  private user: User = new User();
  private userBehaviorSubject: BehaviorSubject<User> = new BehaviorSubject<User>(this.user);

  constructor(private http: HttpClient) { }

  public loadBenutzerprofil(): void {
    this.http.get<Array<User>>(config.environment.baseUri + `/api/identity/v3/benutzerprofil`).subscribe({
      next: (response: any) => {
        this.user = response?.payload;
        localStorage.setItem("token", this.user?.jwtToken);
        this.userBehaviorSubject.next(this.user);
      },
      error: (err: HttpErrorResponse) => {
        if (err.status === 401) {
          window.location.href = config.environment.baseUri;
        }
      }
    });
  }

  public getBenutzer(): Observable<User> {
    return this.userBehaviorSubject.asObservable();
  }
}
